import React from "react";
import theme from "theme";
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | Bienvenue à Nautical Livraison de nourriture
			</title>
			<meta name={"description"} content={"Offrez à vos sens une aventure culinaire avec Nautical Delivery. Bienvenue dans un monde où les saveurs occupent le devant de la scène et où chaque repas est une célébration du goût."} />
			<meta property={"og:title"} content={"Ana Sayfa | Bienvenue à Nautical Livraison de nourriture"} />
			<meta property={"og:description"} content={"Offrez à vos sens une aventure culinaire avec Nautical Delivery. Bienvenue dans un monde où les saveurs occupent le devant de la scène et où chaque repas est une célébration du goût."} />
			<meta property={"og:image"} content={"https://antalyaazure.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://antalyaazure.com/images/decorated-car.svg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<link rel={"apple-touch-startup-image"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<meta name={"msapplication-TileImage"} content={"https://antalyaazure.com/images/decorated-car.svg"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://antalyaazure.com/images/1.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					md-height="500px"
					sm-height="400px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text as="h1" margin="0px 0px 20px 0px" font="normal 900 52px/1.2 --fontFamily-mono">
						GleamCraft Auto
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 300 16px/1.5 --fontFamily-sansVerdana">
						Otomotiv zarafetinin titiz işçilikle buluştuğu bir dünyaya adım atın. GleamCraft Auto Detailing, aracınızın güzelliğini restore etmek ve geliştirmek için sığınağınızdır. Uzmanlık ve tutkunun bir karışımıyla, aracınızın dış ve iç kısmının gerçek parlaklığını ortaya çıkarıyor ve onu bozulmamış bir mükemmellik durumunda bırakıyoruz.
					</Text>
					<Button
						background="--color-primary"
						color="--light"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						padding="12px 30px 12px 30px"
						font="normal 300 16px/1.5 --fontFamily-mono"
						type="link"
						href="/contacts"
						text-decoration-line="initial"
					>
						Kişiler
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 120px 0" quarkly-title="Partners-2">
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 500 56px/1.2 --fontFamily-sans" color="--primary">
				GleamCraft Oto Detaylandırmanın Parlaklığını Deneyimleyin
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
				color="#606469"
				sm-margin="0px 0px 50px 0px"
			>
				GleamCraft'ta sıradan bir oto yıkamanın ötesine geçiyoruz. Yetenekli zanaatkârlardan oluşan ekibimiz kendini aracınızın estetik cazibesini canlandırmaya ve korumaya adamıştır. Boya düzeltmeden iç mekan gençleştirmeye kadar, aracınızı pırıl pırıl bir şahesere dönüştürmekten gurur duyuyoruz.
			</Text>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Description-11">
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
			/>
			<Box width="100%" margin="3vw 0px 0px 0px">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--primary"
					letter-spacing="-.05em"
					lg-font="normal 400 70px/1.1em &quot;Inter&quot;, sans-serif"
					sm-font="normal 400 40px/1.1em &quot;Inter&quot;, sans-serif"
				>
					Neden GleamCraft{" "}
					<br />
					Oto Detaylandırmayı{" "}
					<br />
					Seçmelisiniz?
				</Text>
			</Box>
			<Box
				margin="56px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			/>
			<Box
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="56px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--dark">
					Mükemmellik Takıntısı
					<br />
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--dark">
					Mükemmelliğe olan bağlılığımız, aracınızın her santimetresine azami özen gösterilmesini ve beklentileri aşan bir yüzey elde edilmesini sağlar.
				</Text>
			</Box>
			<Box
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="56px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--dark">
					Detaylandırmada Hassasiyet
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--dark">
					Detaylandırma sanatına inanıyoruz, aracınızın gerçek karakterini ortaya çıkarmak için karmaşık özelliklere odaklanarak onu yolda öne çıkarıyoruz.
					<br />
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="56px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--dark">
					Kişiye Özel Paketler
				</Text>
				<Text font="--base" color="--dark" margin="40px 0px 0px 0px">
					İster kapsamlı bir detay ister hızlı bir rötuş isteyin, detaylandırma paketlerimiz çeşitli ihtiyaçlara hitap ederek her müşteri için kişiselleştirilmiş bir deneyim sağlar.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="56px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text color="--dark" margin="0px 0px 0px 0px" font="--headline3">
					Devrim Niteliğinde Seramik Kaplama
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--dark">
					Aracınızın yüzeyini son teknoloji seramik kaplamamızla koruyun, uzun süreli parlaklık ve hava koşullarına karşı savunma sağlayın.
					<br />
				</Text>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});